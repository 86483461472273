$(function () {
  function registerRules() {
    $.validator.addClassRules({
      fname: {
        required: true,
        minlength: 1,
        maxlength: 255,
      },
      fname_optional: {
        minlength: 1,
        maxlength: 255,
      },
      lname: {
        required: true,
        minlength: 1,
        maxlength: 255,
      },
      lname_optional: {
        minlength: 1,
        maxlength: 255,
      },
      full_name: {
        required: true,
        minlength: 2,
        maxlength: 255,
        fullNameRegex: true,
      },
      cc_name: {
        required: true,
        minlength: 2,
        maxlength: 255,
      },
      company: {},
      title: {},
      email: {
        required: true,
        email: true,
        minlength: 2,
        maxlength: 255,
      },
      'reset-email': {
        email: true,
        minlength: 2,
        maxlength: 255,
      },
      address1: {
        required: false,
        minlength: 2,
        maxlength: 255,
      },
      city: {
        required: false,
        minlength: 2,
        maxlength: 255,
      },
      state: {
        required: false,
        minlength: 2,
        maxlength: 255,
      },
      zip: {
        required: true,
        minlength: 4, //Portugal, Belgium, Germany and other only have 4 numbers in zip code
      },
      country: {
        required: true,
      },
      password: {
        required: true,
        minlength: 6,
        maxlength: 255,
      },
      confirm_password: {
        required: true,
        minlength: 6,
        maxlength: 255,
        equalTo: 'input.password',
      },
      password_optional: {
        minlength: 6,
        maxlength: 255,
      },
      confirm_password_optional: {
        minlength: 6,
        maxlength: 255,
        equalTo: 'input.password',
      },
      password_alternate: {
        required: true,
        minlength: 6,
        maxlength: 255,
      },
      confirm_password_alternate: {
        required: true,
        minlength: 6,
        maxlength: 255,
        equalTo: 'input.password_alternate',
      },
      cc_number: {
        minlength: 14,
        maxlength: 19,
        required: true,
        creditcard: true,
      },
      cc_cvv: {
        digits: true,
        required: true,
        minlength: 3,
        maxlength: 4,
      },
      cc_cvv_optional: {
        digits: true,
        minlength: 3,
        maxlength: 4,
      },
      exp_month: {
        required: true,
        digits: true,
        min: 1,
        max: 12,
      },
      exp_year: {
        required: true,
        digits: true,
        min: new Date().getFullYear(),
        max: new Date().getFullYear() + 15,
      },
      cc_exp_full: {
        required: true,
        ccFullExpDate: true,
      },
      phone: {
        digits: true,
        minlength: 10, // we cannot call a member without the area code
        maxlength: 18,
      },
      verification_code: {
        required: true,
        minlength: 1,
        maxlength: 10,
      },
      binname: {
        required: true,
        minlength: 2,
        maxlength: 255,
      },
      error_option: {
        required: true,
      },
      cancel_reason: {
        required: true,
      },
      vat_number: {
        minlength: 4,
        maxlength: 15,
      },
      org_title: {
        required: true,
        minlength: 2,
        maxlength: 255,
      },
      org_short_name: {
        required: true,
        minlength: 2,
        maxlength: 255,
      },
      org_email_domain: {
        minlength: 2,
        maxlength: 255,
      },
      org_custom_prices: {
        required: true,
        minlength: 1,
        maxlength: 7,
      },
      required: {
        required: true,
      },
    });

    $.extend($.validator.messages, {
      required: 'This field is required.',
      remote: $.validator.format(
        'Sorry, that email is in use, invalid or not allowed.'
      ),
      email: 'Please enter a valid email address.',
      url: 'Please enter a valid URL.',
      date: 'Please enter a valid date.',
      dateISO: 'Please enter a valid date (ISO).',
      number: 'Please enter a valid number.',
      digits: 'Please enter only digits.',
      creditcard: 'Please enter a valid credit card number.',
      equalTo: "Whoops! These values don't seem to match.",
      accept: 'Please enter a value with a valid extension.',
      maxlength: $.validator.format(
        'Please enter no more than {0} characters.'
      ),
      minlength: $.validator.format('Please enter at least {0} characters.'),
      rangelength: $.validator.format(
        'Please enter a value between {0} and {1} characters long.'
      ),
      range: $.validator.format('Please enter a value between {0} and {1}.'),
      max: $.validator.format(
        'Please enter a value less than or equal to {0}.'
      ),
      min: $.validator.format(
        'Please enter a value greater than or equal to {0}.'
      ),
    });

    $.validator.addMethod(
      'fullNameRegex',
      function (value) {
        return /^([^\s]+\s+)+[^\s]+$/i.test(value.trim());
      },
      'Please enter your first and last name.'
    );

    // testing the format of text field is dd/dd
    // month is valid between 1-12
    // year is valid if not in past
    // full expiry valid if not in past
    $.validator.addMethod(
      'ccFullExpDate',
      function (value) {
        var currentYear = parseInt(
          new Date().getFullYear().toString().substr(-2),
          10
        );
        var correctFormat = /^(\d){2}[/](\d){2}$/.test(value);
        var expDateArray = value.split('/');
        var expMonth = parseInt(expDateArray[0], 10);
        var expYear = parseInt(expDateArray[1], 10);
        var validMonthExpiry = expMonth > 0 && expMonth <= 12;
        var validYearExpiry = expYear >= currentYear;

        var validFullExpiry = true;
        if (currentYear === expYear) {
          var currentMonth = new Date().getMonth() + 1;
          validFullExpiry = currentMonth <= expMonth;
        }
        return (
          correctFormat &&
          validMonthExpiry &&
          validYearExpiry &&
          validFullExpiry
        );
      },
      'Please enter a valid credit card expiration date (MM/YY)'
    );

    $.validator.addMethod(
      'cc_number_luhn',
      function (value) {
        const cardNumber = value;
        const trimmed = String(cardNumber).replace(/[\s]/g, '');
        const length = trimmed.length;
        let odd = false;
        let total = 0;
        let calc;
        let calc2;

        if (!/^[0-9]+$/.test(trimmed)) {
          return false;
        }

        for (var i = length; i > 0; i--) {
          calc = parseInt(trimmed.charAt(i - 1));
          if (!odd) {
            total += calc;
          } else {
            calc2 = calc * 2;

            switch (calc2) {
              case 10:
                calc2 = 1;
                break;
              case 12:
                calc2 = 3;
                break;
              case 14:
                calc2 = 5;
                break;
              case 16:
                calc2 = 7;
                break;
              case 18:
                calc2 = 9;
                break;
              default:
                calc2 = calc2;
            }
            total += calc2;
          }
          odd = !odd;
        }

        return total !== 0 && total % 10 === 0;
      },
      'Please enter a valid credit card number'
    );

    $.validator.addMethod(
      'containsString',
      function (value, element, strings) {
        return strings.reduce(function (hasString, string) {
          return hasString || $(element).val().indexOf(string) !== -1;
        }, false);
      },
      'Your input does not contain the required string.'
    );
  }

  if (typeof $.validator === 'function') {
    registerRules();
  }
});
